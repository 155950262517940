<template>
  <div>
    <ValidationProvider tag="div" :name="inputName" :rules="rules">
      <label v-if="inputLabel" :for="inputName" class="mb-10 block font-semibold cursor-pointer">{{ inputLabel }} <span v-if="required || required === ''" class="text-red-100">*</span></label>

      <div class="relative">
        <input
          ref="input"
          class="el-input w-full border border-grey-200 px-12 py-8 outline-none text-base sm:text-sm leading-big sm:leading-normal overflow-hidden focus:border-blue focus:shadow-outline-blue"
          :value="value"
          autocomplete="off"
          :name="inputName"
          v-bind="$attrs"
          :type="isTypePassword ? (showPassword ? 'text' : 'password') : $attrs.type"
          :class="[inputClass, isDisabled, {'password-type': isTypePassword && !showPassword && value.length > 0, 'pr-36': isTypePassword }]"
          @focus="active = true; isInputFocused = true"
          @blur="active = false"
          v-on="{ ...$listeners, input: e => $emit('input', e.target.value) }"
        >
        <span
          v-if="isTypePassword"
          class="icon-eye show-password absolute cursor-pointer leading-none text-xs mr-8"
          :class="{
            'text-grey': showPassword,
            'text-grey-600': !showPassword,
            'z-10': $attrs.actions !== undefined
          }"
          style="transition: color 0.2s"
          @click="showPassword = !showPassword"
        />

        <p v-if="description" class="mt-4 text-xs text-grey-400">{{ description }}</p>
        <slot name="description" />
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  name: 'ElInput',

  props: {
    value: {
      type: [Number, String, Boolean],
      default: ''
    },

    inputClass: { type: String, default: '' },

    inputName: { type: String, default: '' },

    rules: { type: [String, Object], default: '' },

    inputLabel: { type: String, default: '' },

    required: {
      type: [String, Boolean],
      default: false
    },

    description: String

  },

  data () {
    return {
      showPassword: false
    }
  },

  computed: {
    isTypePassword () {
      return this.$attrs.type === 'password'
    },

    isDisabled () {
      return this.$attrs.disabled ? 'cursor-not-allowed bg-grey-100' : ''
    }
  },

  mounted () {
    if (this.$refs.input && this.inputName) {
      this.$refs.input.id = this.inputName
    }
  }
}
</script>

<style lang="scss">
  .show-password {
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
  }

  .el-input {
    &::placeholder {
      color: #6e7583;
    }
    :-ms-input-placeholder {
      color: #6e7583;
    }
    ::-ms-input-placeholder {
      color: #6e7583;
    }
  }
</style>
